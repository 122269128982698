import { FC } from 'react';
import BrandsListView from 'views/brands/BrandsListView/BrandsListView';
import ContactPageView from 'views/contact/ContactPageView';
import FabricDetailsView from 'views/fabrics/FabricDetailsView/FabricDetailsView';
import FabricEditView from 'views/fabrics/FabricEditView/FabricEditView';
import FabricsListView from 'views/fabrics/FabricsListView/FabricsListView';
import FeedbacksListView from 'views/feedbacks/FeedbacksListView';
import HomepageView from 'views/home/HomepageView';
import InspirationCreationView from 'views/inspirations/InspirationCreationView/InspirationCreationView';
import InspirationDetailsView from 'views/inspirations/InspirationDetailsView/InspirationDetailsView';
import InspirationEditView from 'views/inspirations/InspirationEditView/InspirationEditView';
import InspirationsListView from 'views/inspirations/InspirationsListView/InspirationsListView';
import ForgetPasswordView from 'views/login/ForgetPasswordView';
import LoginView from 'views/login/LoginView';
import ResetPasswordView from 'views/login/ResetPasswordView';
import SignInView from 'views/login/SignInView';
import PatternCreationView from 'views/patterns/PatternCreationView/PatternCreationView';
import PatternDetailsView from 'views/patterns/PatternDetailsView/PatternDetailsView';
import PatternEditView from 'views/patterns/PatternEditView/PatternEditView';
import PatternsListView from 'views/patterns/PatternsListView/PatternsListView';
import ProfileDetailsView from 'views/profiles/ProfileDetailsView';
import ProjectCreationView from 'views/projects/ProjectCreationView/ProjectCreationView';
import ProjectDetailsView from 'views/projects/ProjectDetailsView/ProjectDetailsView';
import ProjectEditView from 'views/projects/ProjectEditView/ProjectEditView';
import ProjectsListView from 'views/projects/ProjectsListView/ProjectsListView';
import StashItemCreationView from 'views/stash/StashItemCreationView/StashItemCreationView';
import StashListView from 'views/stash/StashListView';
import SubscriptionView from 'views/subscription/SubscriptionView';
import YarnDetailsView from 'views/yarns/YarnDetailsView/YarnDetailsView';
import YarnEditView from 'views/yarns/YarnEditView/YarnEditView';
import YarnsListView from 'views/yarns/YarnsListView/YarnsListView';
import CreationCreationView from '../views/creations/CreationCreationView/CreationCreationView';
import CreationDetailsView from '../views/creations/CreationDetailsView/CreationDetailsView';
import CreationEditView from '../views/creations/CreationEditView/CreationEditView';
import CreationsListView from '../views/creations/CreationsListView/CreationsListView';
import InspirationHelpPanel from '../views/inspirations/InspirationHelpPanel/InspirationHelpPanel';
import InspirationsCategoriesListView
	from '../views/inspirations/InspirationsCategoriesListView/InspirationsCategoriesListView';
import InspirationsListForCategoryView
	from '../views/inspirations/InspirationsListForCategoryView/InspirationsListForCategoryView';
import InspirationsListForTagView from '../views/inspirations/InspirationsListForTagView/InspirationsListForTagView';
import InspirationsTagsListView from '../views/inspirations/InspirationsTagsListView/InspirationsTagsListView';
import PatternFormHelpPanel from '../views/patterns/PatternForm/PatternFormHelpPanel/PatternFormHelpPanel';
//import PatternsListHelpPanel from '../views/patterns/PatternsListView/PatternsListHelpPanel/PatternsListHelpPanel';
import PrivacyPolicyView from '../views/privacy/PrivacyPolicyView';
import ProjectHelpPanel from '../views/projects/ProjectHelpPanel';
//import StashHelpPanel from '../views/stash/StashHelpPanel';
import WipPageView from '../views/wip/WipPageView';

export type RouteProps = {
	path: string,
	name?: string,
	Component: FC,
	needAuthentication?: boolean,
	breadcrumbs?: { name: string, link: string }[]
	HelpPanelContent?: FC
	panelName?: string
}

const patternsRoutes: RouteProps[] = [
	{
		path: 'patterns',
		name: 'My patterns',
		Component: PatternsListView,
		needAuthentication: true,
		//HelpPanelContent: PatternsListHelpPanel,
		panelName: 'patternList'
	},
	{
		path: 'patterns/sewing',
		name: 'Sewing patterns',
		Component: PatternsListView,
		needAuthentication: true,
		//HelpPanelContent: PatternsListHelpPanel,
		panelName: 'patternList'
	},
	{
		path: 'patterns/knit',
		name: 'Knit patterns',
		Component: PatternsListView,
		needAuthentication: true,
		//HelpPanelContent: PatternsListHelpPanel,
		panelName: 'patternList'
	},
	{
		path: 'patterns/crochet',
		name: 'Crochet patterns',
		Component: PatternsListView,
		needAuthentication: true,
		//HelpPanelContent: PatternsListHelpPanel,
		panelName: 'patternList'
	},
	{
		path: 'patterns/other',
		name: 'Other patterns',
		Component: PatternsListView,
		needAuthentication: true,
		//HelpPanelContent: PatternsListHelpPanel,
		panelName: 'patternList'
	},
	{
		path: 'patterns/new',
		name: 'New pattern',
		Component: PatternCreationView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My patterns', link: '/patterns' }],
		HelpPanelContent: PatternFormHelpPanel,
		panelName: 'patternForm'
	},
	{
		path: 'patterns/:id',
		name: 'Pattern details',
		Component: PatternDetailsView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My patterns', link: '/patterns' }]
	},
	{
		path: 'patterns/:id/edit',
		name: 'Pattern edition',
		Component: PatternEditView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My patterns', link: '/patterns' }],
		HelpPanelContent: PatternFormHelpPanel,
		panelName: 'patternForm'
	},
];

const stashRoutes = [
	{
		path: 'stash',
		name: 'My stash',
		Component: StashListView,
		needAuthentication: true,
		//HelpPanelContent: StashHelpPanel
	},
	{
		path: 'stash/new',
		name: 'New item',
		Component: StashItemCreationView,
		needAuthentication: true,
		//HelpPanelContent: StashHelpPanel
	},
	{
		path: 'fabrics',
		name: 'Fabrics',
		Component: FabricsListView,
		needAuthentication: true,
		//HelpPanelContent: StashHelpPanel
	},
	{
		path: 'fabrics/:id',
		name: 'Fabric details',
		Component: FabricDetailsView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My fabrics', link: '/fabrics' }],
		//HelpPanelContent: StashHelpPanel
	},
	{
		path: 'fabrics/:id/edit',
		name: 'Fabric edition',
		Component: FabricEditView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My fabrics', link: '/fabrics' }],
		//HelpPanelContent: StashHelpPanel
	},
	{
		path: 'yarn',
		name: 'Yarn',
		Component: YarnsListView,
		needAuthentication: true,
		//HelpPanelContent: StashHelpPanel
	},
	{
		path: 'yarn/:id/edit',
		name: 'Yarn edition',
		Component: YarnEditView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My yarn', link: '/yarn' }],
		//HelpPanelContent: StashHelpPanel
	},
	{
		path: 'yarn/:id',
		name: 'Yarn details',
		Component: YarnDetailsView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My yarn', link: '/yarn' }],
		//HelpPanelContent: StashHelpPanel
	},
];

const inspirationsRoutes = [
	{
		path: 'inspirations',
		name: 'Inspirations',
		Component: InspirationsListView,
		needAuthentication: true,
		HelpPanelContent: InspirationHelpPanel
	},
	{
		path: 'inspirations/new', name: 'New inspiration',
		Component: InspirationCreationView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My inspirations', link: '/inspirations' }],
		HelpPanelContent: InspirationHelpPanel
	},
	{
		path: 'inspirations/tags', name: 'My inspirations by tags',
		Component: InspirationsTagsListView,
		needAuthentication: true,
		HelpPanelContent: InspirationHelpPanel
	},
	{
		path: 'inspirations/tags/:tag', name: 'By tag',
		Component: InspirationsListForTagView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My inspirations by tags', link: '/inspirations/tags' }],
		HelpPanelContent: InspirationHelpPanel
	},
	{
		path: 'inspirations/categories', name: 'My inspirations by categories',
		Component: InspirationsCategoriesListView,
		needAuthentication: true,
		HelpPanelContent: InspirationHelpPanel
	},
	{
		path: 'inspirations/categories/:category', name: 'By category',
		Component: InspirationsListForCategoryView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My inspirations by categories', link: '/inspirations/categories' }],
		HelpPanelContent: InspirationHelpPanel
	},
	{
		path: 'inspirations/:id',
		name: 'Inspiration details',
		Component: InspirationDetailsView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My inspirations', link: '/inspirations' }],
		HelpPanelContent: InspirationHelpPanel
	},
	{
		path: 'inspirations/:id/edit',
		name: 'Inspiration edition',
		Component: InspirationEditView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My inspirations', link: '/inspirations' }],
		HelpPanelContent: InspirationHelpPanel
	},
];

const projectsRoutes = [
	{
		path: 'projects',
		name: 'My projects',
		Component: ProjectsListView, needAuthentication: true,
		HelpPanelContent: ProjectHelpPanel
	},
	{
		path: 'projects/new',
		name: 'New project',
		Component: ProjectCreationView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My projects', link: '/projects' }],
		HelpPanelContent: ProjectHelpPanel
	},
	{
		path: 'projects/:id',
		name: 'Project details',
		Component: ProjectDetailsView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My projects', link: '/projects' }],
		HelpPanelContent: ProjectHelpPanel
	},
	{
		path: 'projects/:id/edit',
		name: 'Edit project',
		Component: ProjectEditView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My projects', link: '/projects' }],
		HelpPanelContent: ProjectHelpPanel
	},
];

const creationsRoutes = [
	{
		path: 'creations',
		name: 'My creations',
		Component: CreationsListView,
		needAuthentication: true,
	},
	{
		path: 'creations/new',
		name: 'New creation',
		Component: CreationCreationView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My creations', link: '/creations' }],
	},
	{
		path: 'creations/:id',
		name: 'Creation details',
		Component: CreationDetailsView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My creations', link: '/creations' }],
	},
	{
		path: 'creations/:id/edit',
		name: 'Edit creation',
		Component: CreationEditView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My creations', link: '/creations' }],
	},
];

const subscriptionRoutes = [
	{ path: 'subscription', name: 'Subscription', Component: SubscriptionView, needAuthentication: false },
];

const resourcesRoutes = [
	{ path: 'brands', name: 'Brands', Component: BrandsListView, needAuthentication: false },
];

const feedbacksRoutes = [
	{ path: 'feedbacks', name: 'Feedbacks', Component: FeedbacksListView, needAuthentication: false },
];
const profileRoutes = [
	{ path: 'profile', name: 'Profile', Component: ProfileDetailsView, needAuthentication: true },
];
const loginRoutes: RouteProps[] = [
	{ path: 'login', name: 'Login', Component: LoginView, needAuthentication: false },
	{ path: 'signin', name: 'Signin', Component: SignInView, needAuthentication: false },
	{ path: 'forgetPassword', name: 'Forget password', Component: ForgetPasswordView, needAuthentication: false },
	{ path: 'resetPassword', name: 'Reset password', Component: ResetPasswordView, needAuthentication: false },
];
const otherRoutes = [
	{ path: 'contact', name: 'Contact', Component: ContactPageView, needAuthentication: false },
	{ path: 'faq', name: 'FAQ', Component: WipPageView, needAuthentication: false },
	{
		path: 'privacy',
		name: 'Privacy',
		Component: PrivacyPolicyView,
		needAuthentication: false
	},
];

const routes: RouteProps[] = [
	{ path: '/', Component: HomepageView, needAuthentication: false, breadcrumbs: [] },
	...patternsRoutes,
	...stashRoutes,
	...projectsRoutes,
	...creationsRoutes,
	...inspirationsRoutes,
	...resourcesRoutes,
	...feedbacksRoutes,
	...profileRoutes,
	...loginRoutes,
	...subscriptionRoutes,
	...otherRoutes
];

export default routes;
