export const fr = {
	'New pattern': 'Nouveau patron',
	'Pattern name': 'Nom du patron',
	'Brand': 'Marque',
	'Notes': 'Notes',
	'My patterns': 'Mes patrons',
	'Add new pattern': 'Ajouter un patron',
	'Feedbacks': 'Suggestions',
	'Guide': 'Guide',
	'Brands': 'Marques',
	'Remember me': 'Se souvenir de moi',
	'Login': 'Connexion',
	'Username': 'Nom d\'utilisateur',
	'Password': 'Mot de passe',
	'Forgot your password': 'Mot de passe oublié',
	'Profile': 'Profil',
	'No account yet ? Sign in': 'Pas encore de compte? Inscrivez-vous',
	'here': 'ici',
	'About us': 'A propos',
	'Contact': 'Contact',
	'Sign in': 'Création de compte',
	'Create account': 'Créer le compte',
	'Wishlist': 'Liste de souhaits',
	'No account found for the provided credentials': 'Email et/ou mot de passe incorrect(s)',
	'Image URL': 'URL de l\'image',
	'Categories': 'Catégories',
	'Self drafted pattern': 'Patron personnel',
	'Projector': 'Projecteur',
	'A4 with layers': 'A4 avec calques',
	'US Letter with layers': 'US Letter avec calques',
	'A0 with layers': 'A0 avec calques',
	'Projector with layers': 'Projecteur avec calques',
	'Custom projector file': 'Projecteur personnalisé',
	'Available formats': 'Formats disponibles',
	'Tags': 'Tags',
	'Recommended fabrics': 'Tissus conseillés',
	'Bought in': 'Acheté en',
	'Destined to': 'Destiné à',
	'Everyone': 'Tout le monde',
	'Women': 'Femmes',
	'Men': 'Hommes',
	'Children': 'Enfants',
	'Language': 'Langue',
	'Difficulty': 'Difficulté',
	'Shop pattern link': 'Lien du patron',
	'Free pattern': 'Patron gratuit',
	'Video name': 'Nom de la vidéo',
	'Video link': 'Lien de la vidéo',
	'My inspirations': 'Mes inspirations',
	'My fabrics': 'Mes tissus',
	'Files': 'Fichiers',
	'File name': 'Nom du fichier',
	'File link': 'Lien du fichier',
	'Save pattern': 'Sauvegarder le patron',
	'Custom filters': 'Filtres personnalisés',
	'Logout': 'Se déconnecter',
	'Profile settings': 'Paramètres du compte',
	'Filters': 'Filtres',
	'Advanced filters': 'Filtres avancés',
	'Search results': ' Résultats de recherche ({{count}} résultat(s))',
	'No results': 'Aucun résultat',
	'NO IMAGE AVAILABLE': 'PAS D\'IMAGE DISPONIBLE',
	'Validate': 'Valider',
	'Paper': 'Papier',
	'Applicable size chart': 'Taille patron à utiliser',
	'OR': 'OU',
	'Name is mandatory': 'Veuillez remplir le nom',
	'Click to browse or drop image': 'Cliquez pour parcourir ou faites glisser une image',
	'a4': 'A4',
	'a4-layered': 'A4 avec calques',
	'us-letter': 'US Letter',
	'us-letter-layered': 'US Letter avec calques',
	'a0': 'A0',
	'a0-layered': 'A0 avec calques',
	'projector': 'Projecteur',
	'projector-layered': 'Projecteur avec calques',
	'custom-projector': 'Projecteur personnalisé',
	'paper': 'Papier',
	'Fabric requirements': 'Métrage requis',
	'Fabric details': 'Détails tissu',
	'My notes': 'Mes notes',
	'My files': 'Mes fichiers',
	'My videos': 'Mes vidéos',
	'Username already taken': 'Nom d\'utilisateur non disponible',
	'Username updated': 'Nom d\'utilisateur modifié',
	'You must provide the link of the video': 'Saisissez l\'url de la vidéo',
	'Invalid email': 'Email non valide',
	'Reset password': 'Réinitialiser le mot de passe',
	'Video url': 'URL de la vidéo',
	'File url': 'URL du fichier',
	'Click to edit': 'Cliquez pour modifier',
	'Create an account': 'Créer un compte',
	'Create': 'Créer',
	'No result for that search': 'Pas de résultat pour cette recherche',
	'My library': 'Ma bibliotèque',
	'Resources': 'Ressources',
	'Demonstration': 'Démonstration',
	'My brands': 'Mes marques',
	'Add new item': 'Ajouter un article',
	'New fabric': 'Nouveau tissu',
	'Name': 'Nom',
	'Shop': 'Boutique',
	'Available quantity': 'Quantité disponible',
	'Fabric types': 'Types de tissu',
	'4-way-stretch': 'Bi-stretch',
	'2-way-stretch': 'Stretch',
	'woven': 'Chaine et trame',
	'Type to search or create': 'Tapez pour chercher ou créer',
	'Brand name': 'Nom de la marque',
	'Brand website url': 'Site de la marque',
	'Brands list': 'Annuaire des marques',
	'By': 'Par {{username}}',
	'Your feedback': 'Votre suggestion',
	'Submit': 'Envoyer',
	'Cancel': 'Annuler',
	'Save': 'Sauvegarder',
	'My filters': 'Mes filtres',
	'Custom projector': 'Projecteur personnalisé',
	'US letter with layers': 'US letter avec calques',
	'Delete my account': 'Supprimer mon compte',
	'Deleting your account will delete permanently all the patterns and fabrics you created': 'Supprimer votre compte supprimera définitivement tous les patrons et tissus que vous avez créé',
	'Do you confirm you want to delete your account': 'Confirmez-vous que vous souhaitez supprimer votre compte',
	'Confirm': 'Confirmer',
	'Account deletion': 'Suppression de compte',
	'On this page, you will find a list of verified brands selling pdf patterns': 'Sur cette page, vous trouverez une liste vérifiée de marques de patrons PDF',
	'Feel free to submit a brand you like if it is not in the list in the form below': 'N\'hésitez pas à proposer une marque si elle ne se trouve pas dans la liste via le formulaire en bas de page',
	'Did not find your favorite brand in the list? Submit it': 'Vous n\'avez pas trouvé votre marque préférée dans la liste ? Proposez-la',
	'Manage brands': 'Gérer les marques',
	'Manage users': 'Gérer les utilisateurs',
	'Logs': 'Logs',
	'Stats': 'Statistiques',
	'Site management': 'Gestion du site',
	'Console': 'Console',
	'Users list': 'Liste des utilisateurs',
	'Validated': 'Validé',
	'Website': 'Site web',
	'Delete pattern': 'Supprimer le patron',
	'Are you sure you want to delete that pattern': 'Voulez-vous vraiment supprimer ce patron',
	'Pattern deletion': 'Suppression de patron',
	'User email': 'Email utilisateur',
	'Role': 'Rôle',
	'Creation date': 'Date de création',
	'Last connection date': 'Dernière connexion',
	'Last modification date': 'Dernière modification',
	'Delete fabric': 'Supprimer le tissu',
	'Fabric deletion': 'Suppression de tissu',
	'Are you sure you want to delete that fabric': 'Voulez-vous vraiment supprimer ce tissu',
	'Projects you can do with that fabric_one': 'Projets réalisables avec ce tissu ({{count}} projet)',
	'Projects you can do with that fabric_other': 'Projets réalisables avec ce tissu ({{count}} projets)',
	'Fabrics you can use for that pattern_one': 'Tissus utilisables pour ce patron ({{count}} tissu)',
	'Fabrics you can use for that pattern_other': 'Tissus utilisables pour ce patron ({{count}} tissus)',
	'We found no patterns in your collection for that type and quantity of fabric': 'Nous n\'avons trouvé aucun patron dans votre collection pour ce type et cette quantité de tissu',
	'We found no fabrics in your collection for that pattern': 'Nous n\'avons trouvé aucun tissu adapté pour ce patron dans votre inventaire',
	'Colors': 'Couleurs',
	'Design/Pattern': 'Motifs',
	'Composition': 'Composition',
	'Other informations': 'Informations supplémentaires',
	'Fitting adjustment required': 'Ajustements de taille nécessaires',
	'Projector adjustment required': 'Patron à adapter pour la projection',
	'Sewing patterns quantity': 'Total patrons de couture',
	'Fabrics quantity': 'Total inventaire',
	'Projects quantity': 'Total projets',
	'Inspirations quantity': 'Total inspirations',
	'Unlimited': 'Illimité',
	'Go back to patterns list': 'Revenir à la liste des patrons',
	'Go back to fabrics list': 'Revenir à la liste des tissus',
	'You have reached the limits of sewing patterns you are allowed to create': 'Vous ne pouvez pas ajouter plus de patrons',
	'You have reached the limits of stash items you are allowed to create': 'Vous ne pouvez pas ajouter plus d\'articles',
	'Brands updated': 'Marques mises à jour',
	'Go back to main site': 'Retourner à l\'accueil',
	'Brand submitted': 'Marque proposée',
	'My projects': 'Mes projets',
	'Add new inspiration': 'Ajouter une inspiration',
	'Sewing': 'Couture',
	'Knit': 'Tricot',
	'Crochet': 'Crochet',
	'Other': 'Autre',
	'My stash': 'Mon inventaire',
	'Yarn': 'Laine/Fils',
	'Fabrics': 'Tissus',
	'New item': 'Nouvel article',
	'Go back to stash': 'Revenir à l\'inventaire',
	'Fabric': 'Tissu',
	'NotStarted': 'Non commencé',
	'Started': 'En cours',
	'Paused': 'En pause',
	'ToAdjust': 'Ajustements requis',
	'Finished': 'Fini',
	'Abandoned': 'Abandonné',
	'Add new project': 'Ajouter nouveau projet',
	'Home': 'Accueil',
	'Edit': 'Modifier',
	'Delete': 'Supprimer',
	'Related elements': 'Éléments liés',
	'Project details': 'Détails projet',
	'meters': 'mètres',
	'yard': 'yard',
	'grams': 'grammes',
	'Related projects default': 'Projets en cours',
	'Related projects': 'Projets en cours ({{count}})',
	'Projects you can do with that yarn': 'Projets que vous pouvez faire avec ce fil',
	'Yarn details': 'Détails fil',
	'Sewing patterns': 'Patrons de couture',
	'Knit patterns': 'Patrons tricot',
	'Crochet patterns': 'Patrons crochet',
	'Other patterns': 'Patrons divers',
	'View': 'Voir',
	'Create project': 'Créer projet',
	'Quantity': 'Quantité',
	'Required quantity': 'Quantité requise',
	'Pattern details': 'Détails du patron',
	'Project creation': 'Création de projet',
	'Item deletion': 'Suppresion d\'article',
	'Are you sure you want to delete this item': 'Êtes-vous sûr de vouloir supprimer cet élément',
	'Are you sure you want to delete that yarn': 'Êtes-vous sûr de vouloir supprimer ce fil',
	'Project deletion': 'Suppression de projet',
	'Inspiration deletion': 'Suppression d\'inspiration',
	'Active filters': 'Filtres actifs',
	'New inspiration': 'Nouvelle inspiration',
	'Status': 'Statut',
	'Recipient': 'Destinataire',
	'Started at': 'Commencé le',
	'Finished at': 'Fini le',
	'Needles size': 'Taille d\'aiguilles',
	'Weight categories': 'Catégories/poids',
	'Stitches': 'Mailles',
	'Rows': 'Rangées',
	'Gauge': 'Échantillon',
	'Sample size': 'Taille de l\'échantillon',
	'Project name': 'Nom du projet',
	'Are you sure you want to delete that project': 'Êtes-vous sûr de vouloir supprimer le projet {{projectName}}',
	'Recipients': 'Destinataires',
	'Materials': 'Matériel nécessaire',
	'Pattern(s)': 'Patron(s)',
	'External link': 'Lien externe',
	'New project': 'Nouveau projet',
	'Select': 'Sélectionner',
	'Create a project with these pattern and fabric': 'Créer un projet avec ce patron et ce tissu',
	'Create a project with these pattern and yarn': 'Créer un projet avec ce patron et ce fil',
	'Additional informations': 'Informations complémentaires',
	'Subscription': 'Abonnement',
	'Privacy': 'Confidentialité',
	'Quantity required': 'Quantité requise',
	'Report a bug': 'Signaler un bug',
	'How to add an image': 'Comment ajouter une image',
	'Close': 'Fermer',
	'Welcome to the demonstration space': 'Bienvenue sur l\'espace démonstration',
	'Demonstration home': 'Accueil démonstration',
	'characters': 'caractères',
	'Pattern edition': 'Modification de patron',
	'Or': 'Ou',
	'Fabric edition': 'Modification de tissu',
	'By tags': 'Par tags',
	'By categories': 'Par catégories',
	'By tag': 'Par tag',
	'By category': 'Par catégorie',
	'My inspirations by tags': 'Inspirations par tags',
	'My inspirations by categories': 'Inspirations par catégories',
	'Details': 'Détails',
	'Inspiration details': 'Détails',
	'Media type': 'Type de media',
	'Image url': 'Url de l\'image',
	'Select or add': 'Sélectionner ou créer',
	'Projects': 'Projets',
	'Pattern creation': 'Création de patron',
	'Load more': 'Charger plus',
	'My yarn': 'Mes laines et fils',
	'Yarns you can use for that pattern': 'Laines que vous pouvez utiliser pour ce patron',
	'We found no yarns in your collection for that pattern': 'Nous n\'avons trouvé aucune laine dans votre stock pour ce patron',
	'Yarn weight': 'Catégorie de laine',
	'Projects list': 'Liste des projets',
	'My creations': 'Mes réalisations',
	'Add new creation': 'Ajouter une réalisation',
	'Project deleted': 'Projet supprimé',
	'Subject': 'Objet',
	'Get information': 'Obtenir des informations',
	'Professional contact': 'Contact professionnel',
	'Edit project': 'Édition de projet',
	'New creation': 'Nouvelle réalisation',
	'Bug report': 'Signaler un bug',
	'If you have any question or want to report a bug send me a mail at': 'Pour toute question ou signalement de bug, vous pouvez envoyer un mail à',
	'or use the contact form below': 'ou utiliser le formulaire ci-dessous',
	'Email address': 'Adresse mail',
	'News': 'Actualités',
	'Inspiration edition': 'Édition',
	'Free sewing patterns': 'Patrons de couture gratuits',
	'Cotton yarn': 'Laine contenant du coton',
	'Shell stitch crochet inspirations': 'Inspirations crochet avec motif "shell stitch"',
	'Always free': 'Toujours gratuit',
	'No file': 'Aucun fichier',
	'No video': 'Aucune video',
	'Add': 'Ajouter',
	'Add video': 'Ajouter une video',
	'Add file': 'Ajouter un fichier',
	'Open link': 'Ouvrir le lien',
	'Copy link': 'Copier le lien',
	'women': 'Femme',
	'men': 'Homme',
	'children': 'Enfants',
	'everyone': 'Tout le monde',
	'Links': 'Liens',
	'Recommended yarn weight': 'Catégorie de laine recommandée',
	'Change custom limits': 'Modifier limites',
	'Add news': 'Ajouter',
	'Disabled in demo mode': 'Désactivé en mode démo',
	'Yarn edition': 'Modification',
	'Forget password': 'Mot de passe oublié',
	'Signin': 'Création de compte',
	'Default': 'Par défault',
	'External': 'Externe',
	'Welcome to My Crafting Cloud': 'Bienvenue sur My Crafting Cloud',
	'Have any suggestion to improve the app ? Leave a feedback, or like an existing feedback so that I can know what features you would like me to add': 'Vous avez des suggestions pour améliorer l\'app? Laissez un avis, ou likez un avis existant pour que je sache quelles fonctionnalités vous souhaitez avoir!',
	'Add a note': 'Ajouter une note',
	'No notes yet': 'Aucune note'
};
