import { useCallback, useEffect, useState } from 'react';
import { faBars, faClose, faPowerOff, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { useLogoutMutation, useReportBugMutation } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import TextArea from 'components/Inputs/TextArea';
import LanguagePicker from 'components/LanguagePicker/LanguagePicker';
import ModalContainer from 'components/ModalContainer/ModalContainer';
import { useAuthenticatedUser } from 'customHooks/data/useAuthenticatedUser';
import useFirebaseAuthentication from 'customHooks/services/useFirebaseAuthentication';
import useNotifications from 'customHooks/utils/useNotifications';
import useScreenWidth from 'customHooks/utils/useScreenWidth';
import { MainPageSidebar } from 'pages/MainPage/MainPageSidebar/MainPageSidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import styled from 'styled-components';
import { StyledHeader, StyledLogoContainer, StyledMobileMenu } from './MainPageHeaderStyles';
import NotesDrawerView from '../../../views/notes/NotesDrawerView/NotesDrawerView';

const StyledReportBug = styled.div`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`;

export const MainPageHeader = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { notifyInfo } = useNotifications();
	const { isMediumScreen, isSmallScreen, isSuperSmallScreen, isLargeScreen } = useScreenWidth();
	const [logout] = useLogoutMutation();
	const { user } = useAuthenticatedUser();
	const { signOutOfGoogle } = useFirebaseAuthentication();
	const [menuOpened, setMenuOpened] = useState<boolean>(false);
	const [bugReportModalOpened, setBugReportModalOpened] = useState(false);
	const [bugReport, setBugReport] = useState('');
	const [reportBug] = useReportBugMutation({
		onCompleted: () => {
			setBugReport('');
			setBugReportModalOpened(false);
			notifyInfo('Bug reported');
		}
	});

	const onLogout = useCallback(() => {
		signOutOfGoogle();
		logout().then(() => window.location.reload());
	}, [logout, signOutOfGoogle]);

	useEffect(() => {
		setMenuOpened(false);
	}, [location]);

	return <StyledHeader className="justify-end justify-md-between">
		{!isSmallScreen && <ModalContainer
			opened={bugReportModalOpened}
			onClose={() => {
				setBugReportModalOpened(false);
				setBugReport('');
			}}
			title={translate({ key: 'Bug report' })}
			onConfirm={() => reportBug({
				variables: {
					message: bugReport
				}
			})}
		>
			<TextArea
				value={bugReport}
				onChange={content => setBugReport(content)}
				rows={10}/>
		</ModalContainer>}
		{isMediumScreen && <StyledLogoContainer justifyContent="flex-start">
			<FontAwesomeIcon icon={menuOpened ? faClose : faBars} onClick={() => setMenuOpened(!menuOpened)}/>
			<span className="pl-1" onClick={() => navigate('/')}>
				My Crafting Cloud
			</span>
		</StyledLogoContainer>}
		{isMediumScreen && (
			<StyledMobileMenu visible={menuOpened}>
				<MainPageSidebar/>
			</StyledMobileMenu>
		)}
		{!isSmallScreen && <StyledReportBug className="w-100" onClick={() => setBugReportModalOpened(true)}>
			{translate({ key: 'Report a bug' })}
		</StyledReportBug>}
		{isLargeScreen && user?.role === 'ADMIN' && (
			<div className="pr-1 w-100">
				<Link to="/admin">
					{translate({ key: 'Admin dashboard' })}
				</Link>
			</div>
		)}
		<FlexContainer className="w-100 w-md-fit-content justify-end">
			{!user && <>
				{isSuperSmallScreen ?
					<Link to="/login">
						<FontAwesomeIcon icon={faUser}/>
					</Link> :
					<Button
						label={translate({ key: 'Login' })}
						onClick={() => navigate('/login')}/>
				}
				{!isSuperSmallScreen && isMediumScreen && (
					<Link to="/signin"><FontAwesomeIcon icon={faUserPlus}/></Link>
				)}
				{isLargeScreen && (
					<div className="pl-1">
						<span>
							{translate({ key: 'No account yet ? Sign in' })}
							&nbsp;
						</span>
						<Link to="/signin">{translate({ key: 'here' })}</Link>
					</div>
				)}
			</>
			}
			{user && <>
				<NotesDrawerView/>
				<Tooltip title={translate({ key: 'Profile' })}>
					<Link to="/profile">
						<FontAwesomeIcon icon={faUser}/>
					</Link>
				</Tooltip>
				<div onClick={onLogout} className="mr logout">
					{isMediumScreen ?
						<FontAwesomeIcon icon={faPowerOff}/> :
						<span className="pl-1">
							{translate({ key: 'Logout' })}
						</span>
					}
				</div>
			</>}
			<LanguagePicker/>
		</FlexContainer>
	</StyledHeader>;
};
