import React, { useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem } from '@mui/material';
import { NotesDetailsFragment, useGetNotesListQuery } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import { translate } from 'services/i18n';
import { dateToShortString } from 'services/utils';
import styled from 'styled-components';
import NotesDetailsView from './NotesDetailsView';
import NotesForm from './NotesForm/NotesForm';

const StyledNotesListContainer = styled.div`
  min-width: 20em;
  height: 100%;
  border-left: 1px solid lightgrey;

  .w-separator:not(:last-child) {
    border-bottom: 1px solid lightgrey;
  }
`;

const StyledContainer = styled(FlexContainer)`
  height: 100%;
  justify-content: flex-start;
`;

const StyledDetailsContainer = styled(StyledContainer)`
  &.opened {
    width: 50em;
  }

  padding: 1em;
`;

const StyledDrawerHeader = styled(FlexContainer)`
  height: 3em;
  padding: 0;
  font-weight: bold;
  font-size: 2em;
  border-bottom: 1px solid lightgrey;
`;

const StyledNotesPreview = styled.div`
  /*  white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;*/
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  width: 18em;
  color: lightgrey;
`;

const NotesListView = () => {
	const { data } = useGetNotesListQuery();
	const notes = data?.notesList?.notes || [];
	const [currentlyOpenedNotes, setCurrentlyOpenedNotes] = useState<null | NotesDetailsFragment>(null);
	const [creatingNewNote, setCreatingNewNote] = useState(false);
	return (
		<StyledContainer column>
			<StyledDrawerHeader>{translate({ key: 'My notes' })}</StyledDrawerHeader>
			<StyledContainer>
				<StyledDetailsContainer column className={currentlyOpenedNotes ? 'opened' : ''}>
					{currentlyOpenedNotes && <NotesDetailsView
						onEditSaved={(updatedNote => setCurrentlyOpenedNotes(updatedNote))}
						notes={currentlyOpenedNotes}
					/>}
					{creatingNewNote && <NotesForm
						onCancel={() => setCreatingNewNote(false)}
						onSaved={(updatedNote: NotesDetailsFragment) => {
							setCreatingNewNote(false);
							setCurrentlyOpenedNotes(updatedNote);
						}}
					/>}
				</StyledDetailsContainer>
				<StyledNotesListContainer>
					{!notes.length && <div>{translate({ key: 'No notes yet' })}</div>}
					<FlexContainer justifyContent="end">
						<Button
							className="mt-1 mr-1"
							icon={<FontAwesomeIcon icon={faPlus}/>}
							label={translate({ key: 'Add a note' })}
							onClick={() => {
								setCurrentlyOpenedNotes(null);
								setCreatingNewNote(true);
							}}
						/>
					</FlexContainer>
					<List>
						<ListItem>
						</ListItem>
						{notes.map(note => (
							<ListItem
								className="cursor-pointer-bold w-separator"
								key={note._id}
								onClick={() => {
									setCreatingNewNote(false);
									setCurrentlyOpenedNotes(note);
								}}
							>
								<FlexContainer column alignItems={'start'}>
									<FlexContainer
										className="italic"
										justifyContent={'end'}>{dateToShortString(new Date(note.createdAt))}</FlexContainer>
									<div className="bold">{note.title}</div>
									<StyledNotesPreview dangerouslySetInnerHTML={{ __html: note.content }}/>
								</FlexContainer>
							</ListItem>
						))}
					</List>
				</StyledNotesListContainer>
			</StyledContainer>
		</StyledContainer>
	);
};

export default NotesListView;
